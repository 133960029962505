$(function () {
    "use strict";
    $(".preloader").fadeOut();
    $("#main-wrapper").AdminSettings({
        Theme: false,
        Layout: "vertical",
        LogoBg: "skin6",
        NavbarBg: "skin1",
        SidebarType: "full",
        SidebarColor: "skin6",
        SidebarPosition: true,
        HeaderPosition: true,
        BoxedLayout: false,
    });

    LiveForm.setOptions({
        showValid: false,
        showMessageClassOnParent: true,
        messageParentClass: "validation-message-on-parent",
        messageErrorClass: "text-danger",
        controlErrorClass: "is-invalid",
        messageErrorPrefix: "",
    });

    /*LiveForm.setOptions({
        messageErrorClass: 'invalid-tooltip',
        wait: 500
    });*/

    /**
     * Snippet Modals + spinner
     */
    NProgress.configure({
        showSpinner: false,
    });

    $.nette.ext("nprogress", {
        start: function () {
            window.NProgress.start();
        },
        complete: function () {
            window.NProgress.done();
        },
    });

    Nette.addEvent = function (element, on, callback) {
        $(element).on(on, callback);
    };

    /*OLD ORIGINAL SOLUTION - its working when we have physical multiple modals in HTML DOM.. 
    We are not using this (yet) in our project, we instead update modal body directly so there is only one modal in the DOM at a time.*/
    /*$.nette.ext("modals", {
        before: function () {
            this.wasModalOpenedBeforeAjaxRequest = this.isModalOpened();
            console.log(this.wasModalOpenedBeforeAjaxRequest);
        },
        success: function (payload) {
            if (payload.isModal) {
                if (!this.wasModalOpenedBeforeAjaxRequest) {
                    $('.modal-ajax').modal('show');
                } else {
                    $('.modal-ajax').addClass('in').css({'display': 'block'}).modal('show');
                    $('.modal-backdrop').removeClass('fade');
                    $('.modal-backdrop:not(:last)').remove();
                    window.setTimeout(function () {
                        $('.modal-backdrop').addClass('fade');
                    }, 200);
                }
            } else {
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');
                $('.modal-ajax').modal('hide');
            }

            if(payload.modalFormErrors) {
                payload.modalFormErrors.forEach(e => {
                    $('.modal-errors').append('<p>' + e +'</p>');
                });
            }else{
                $('.modal-errors').empty();
            }
        }
    }, {
        wasModalOpenedBeforeAjaxRequest: false,
        isModalOpened: function () {
            return ($('.modal.fade.in').length > 0);
        }
    });*/

    $.nette.ext(
        "modals",
        {
            before: function () {
                this.wasModalOpenedBeforeAjaxRequest = this.isModalOpened();
                this.lastOpenedModalId = this.lastOpenedModal();
            },
            success: function (payload) {
                if (payload.isModal) {
                    if (!this.wasModalOpenedBeforeAjaxRequest) {
                        $(".modal-ajax").modal("show");
                    } else {
                        if (this.lastOpenedModalId == payload.modalId) {
                            $(".modal-ajax").removeClass("fade");
                        }
                        $(".modal-ajax").addClass("in").css({ display: "block" }).modal("show");
                        $(".modal-backdrop").removeClass("fade");
                        $(".modal-backdrop:not(:last)").remove();
                        window.setTimeout(function () {
                            $(".modal-ajax").addClass("fade");
                            $(".modal-backdrop").addClass("fade");
                        }, 200);
                    }
                } else {
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");
                    $(".modal-ajax").modal("hide");
                }

                if (payload.modalFormErrors) {
                    payload.modalFormErrors.forEach((e) => {
                        $(".modal-errors").append("<p>" + e + "</p>");
                    });
                } else {
                    $(".modal-errors").empty();
                }
            },
        },
        {
            wasModalOpenedBeforeAjaxRequest: false,
            isModalOpened: function () {
                return $("body").hasClass("modal-open");
            },
            lastOpenedModal: function () {
                return $(".modal-ajax.show").attr("id");
            },
        }
    );

    $(document).on("show.bs.dropdown", ".dropdown-move-me-outside", function (e) {
        var dropdown = $(e.target).find(".dropdown-menu");
        dropdown.appendTo("body");
        $(this).on("hidden.bs.dropdown", function () {
            dropdown.appendTo(e.target);
        });
    });

    $(document).on("change", ".dataTables_length select", function (e) {
        let id = $(this).attr("name");
        let storageName = "default_" + id;
        localStorage.setItem(storageName, $(this).val());
    });

    $(document).on("show.bs.modal", ".modal", function () {
        $("body").css("padding-right", "0");
    });

    $(document).on("hide.bs.modal", ".modal", function () {
        $("body").css("padding-right", "0");
        $(".modal-body .flatpickr").each(function () {
            if ($(this).flatpickr()) {
                $(this).flatpickr().destroy();
            }
        });
    });

    $(".left-sidebar").hover(
        function () {
            $(".navbar-header").addClass("expand-logo");
        },
        function () {
            $(".navbar-header").removeClass("expand-logo");
        }
    );
    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").on("click", function () {
        $("#main-wrapper").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("fa-bars");
        if ($(".nav-toggler i").hasClass("fa-bars")) {
            $(".nav-toggler i").removeClass("fa-times");
        } else {
            $(".nav-toggler i").addClass("fa-times");
        }
    });
    $(".nav-lock").on("click", function () {
        $("body").toggleClass("lock-nav");
        $(".nav-lock i").toggleClass("mdi-toggle-switch-off");
        $("body, .page-wrapper").trigger("resize");
    });
    $(".search-box a, .app-search .srh-btn").on("click", function () {
        $(".app-search").toggle(200);
        $(".app-search input").focus();
        $(this).closest("form").submit();
    });

    $(".datepicker")
        .datepicker({
            autoclose: true,
            clearBtn: true,
            daysOfWeekHighlighted: [0, 6],
            enableOnReadonly: false,
            language: "cs",
            format: "dd.mm.yyyy",
            todayHighlight: true,
        })
        .on("changeDate", function (e) {
            let inptId = $(this).data("update-id");
            let inpt = $("#" + inptId);

            let newDate = moment(e.date, "dd.mm.YYYY").format("YYYY-MM-DD");
            inpt.val(newDate).trigger("change");
        });

    $(".datepicker").each(function () {
        let inptId = $(this).data("update-id");
        let inpt = $("#" + inptId);

        let d = inpt.val();
        if (d) {
            let newDate = moment(e.date, "YYYY-MM-DD").format("dd.mm.YYYY");
            $(this).val(newDate);
        }
    });

    $(function () {
        $(".service-panel-toggle").on("click", function () {
            $(".customizer").toggleClass("show-service-panel");
        });
        $(".page-wrapper").on("click", function () {
            $(".customizer").removeClass("show-service-panel");
        });
    });

    $(".floating-labels .form-control")
        .on("focus blur", function (e) {
            $(this)
                .parents(".form-group")
                .toggleClass("focused", e.type === "focus" || this.value.length > 0);
        })
        .trigger("blur");

    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });

    $(function () {
        $('[data-toggle="popover"]').popover();
    });

    /*$('.message-center, .customizer-body, .scrollable').perfectScrollbar({
        wheelPropagation: !0
    });*/

    $(".message-center, .customizer-body, .scrollable").each(function () {
        let el = $(this)[0];
        const ps = new PerfectScrollbar(el);
    });

    //Auto-close notification dropdown-menu fix
    $(".mailbox").on("click wheel", function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    $("body, .page-wrapper").trigger("resize");
    $(".page-wrapper").delay(20).show();

    $(".list-task li label").click(function () {
        $(this).toggleClass("task-done");
    });

    $('a[data-action="collapse"]').on("click", function (e) {
        e.preventDefault();
        $(this).closest(".card").find('[data-action="collapse"] i').toggleClass("ti-minus ti-plus");
        $(this).closest(".card").children(".card-body").collapse("toggle");
    });
    // Toggle fullscreen
    $('a[data-action="expand"]').on("click", function (e) {
        e.preventDefault();
        $(this).closest(".card").find('[data-action="expand"] i').toggleClass("mdi-arrow-expand mdi-arrow-compress");
        $(this).closest(".card").toggleClass("card-fullscreen");
    });
    // Close Card
    $('a[data-action="close"]').on("click", function () {
        $(this).closest(".card").removeClass().slideUp("fast");
    });

    $(document).on("click", ".mega-dropdown", function (e) {
        e.stopPropagation();
    });

    var sparklineLogin = function () {
        $(".lastmonth").sparkline([6, 10, 9, 11, 9, 10, 12], {
            type: "bar",
            height: "35",
            barWidth: "4",
            width: "100%",
            resize: true,
            barSpacing: "8",
            barColor: "#2961ff",
        });
    };
    var sparkResize;

    $(window).on("resize", function () {
        clearTimeout(sparkResize);
        sparkResize = setTimeout(sparklineLogin, 500);
    });

    sparklineLogin();

    $(".rodne-cislo").on("change", function () {
        let rc = $(this).val();
        let len = rc.length;
        if (len >= 9) {
            let part = rc.substr(0, 6);
            let day = parseInt(part.substr(4, 2));
            let month = parseInt(part.substr(2, 2));
            if (month > 12) {
                month -= 50;
            }
            let year = parseInt(part.substr(0, 2));
            if (year < 54 && len > 9) {
                year += 2000;
            } else {
                year += 1900;
            }
            let parsed = year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
            console.log(parsed);
            $(".datum-narozeni").val(parsed);
        }
    });

    $(".show-left-part").on("click", function () {
        $(".left-part").toggleClass("show-panel");
        /*$('.show-left-part').toggleClass('fa-bars');
        if($(".show-left-part").hasClass("fa-bars")) {
            $(".show-left-part").removeClass("fa-times");
        }else{
            $(".show-left-part").addClass("fa-times");
        }*/
    });
});

function CountDownTimer(dt, id, days1, days2, day) {
    var end = new Date(dt);

    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var timer, daysText;

    function showRemaining() {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
            clearInterval(timer);
            document.getElementById(id).innerHTML = "EXPIRED!";

            return;
        }
        var days = Math.floor(distance / _day);

        if (days >= 5) {
            daysText = days1;
        } else if (days < 5 && days !== 1) {
            daysText = days2;
        } else if (days === 1) {
            daysText = day;
        }

        document.getElementById(id).innerHTML = days + " " + daysText;
    }

    timer = setInterval(showRemaining, 1000);
}
/** Definice Flash message */
function flashMessage(message, type) {
    let typ;
    if (type === "g") {
        typ = "success";
    } else if (type === "w") {
        typ = "warning";
    } else if (type === "r") {
        typ = "error";
    }

    toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: true,
        progressBar: true,
        positionClass: "toast-top-center",
        preventDuplicates: true,
        onclick: null,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "5000",
        extendedTimeOut: "1000",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
    };
    toastr[typ](message);
}
